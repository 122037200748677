import logo from './logo.svg';
import './App.css';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-flip";
import "swiper/css/pagination";
import "swiper/css/navigation";


import { EffectFlip, Navigation } from "swiper";

function App() {
  function toggleMenu() {
    const menu = document.getElementById("mobileNavContainer");
    const menuicon = document.getElementById("menuIcon");
    menuicon.classList.toggle("close-button");
    menu.classList.toggle("open-menu");
  }
  return (
    <div className="app">
      <div className='mobile-nav' id="mobileNavContainer">
        <div className="mobile-nav-inner">
          <a href="#home" onClick={() => toggleMenu()}>Home</a>
          <div className="divider"></div>
          <a href="#about" onClick={() => toggleMenu()}>About</a>
          <div className="divider"></div>
          <a href="https://opensea.io/collection/art-innovation-science" target="_blank" rel="noreferrer" onClick={() => toggleMenu()}>Gallery</a>
          <div className="divider"></div>
          <a href="#contact" onClick={() => toggleMenu()}>Contact</a>
          <div className="divider"></div>

        </div>


      </div>
      <div className="menu-button" id="menuIcon" onClick={() => toggleMenu()}>
        <div className="line-top"></div>
        <div className="line-bottom"></div>
      </div>
      <nav className='hide-800'>
        <a href="#home">Home</a>
        <a href="#about">About</a>
        <a href="https://opensea.io/collection/art-innovation-science" target="_blank" rel="noreferrer">Gallery</a>
        <a href="#contact">Contact</a>
      </nav>

      <div className="wrapper-home">
        <div className="mobile-photo">
          <div className="fadeBot"></div>
        </div>
        <div className="home" id="home">

          <div className="info fadeIn">
            <h1>Art<br />
              Innovation <br />
              Science </h1>
            
  
            <p>
              They have more in common than we think: <br />
              <em>Wild imagination.  <br />
                Unruly disruption. <br />
                Open creativity.<br />
                Untamed curiosity. </em><br /> <br />
              Still, they’re often treated as separate fields.
              <br /><br />
              As an artist, innovation expert, and social scientist <br />
              I believe there’s lots to gain by bringing them closer together. <br />
              My own art is born out of this belief and the inspiration <br />
              I get daily from each of these fields.

            </p>
            <h2><i>Louise Agersnap</i></h2>
            <div className="social-info">

              <a href="https://opensea.io/collection/art-innovation-science" target="_blank" rel="noreferrer" className="button">Gallery</a>

              <div className="flex-socials">
                <a href="https://twitter.com/LouiseAgersnap"><img src="/assets/twitter.svg" alt="" /></a>
                <a href="https://www.instagram/louiseagersnap"><img src="/assets/instagram.svg" alt="" /></a>
                <a href="https://www.linkedin.com/in/louise-agersnap-632758"><img src="/assets/linkedin.svg" alt="" /></a>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div className="about" id="about">
        <div className="ellipse1">
          <img src="/assets/ellipse.svg" alt="" />
        </div>
        <h1>About Louise Agersnap</h1>
        <hr />
        <div className="card">
          <div className="left">
            <h2>Bio</h2>
            <p>
              The production of artist Louise Agersnap, born in Denmark, is abstract in style, and mostly applying acrylic paint and crayons on canvas, often adding mixed media, such as newspaper, sand, poetry, or photography. She uses brushes, sticks, and even discarded credit cards as artistic tools. <br />
              Louise Agersnap’s artistic works are nurtured by the artistic veins that run in her family, from the artist ancestors on her mother’s side, Jacob Agersnap (1888-1958) and Hans Agersnap (1857-1925) to her late father, Peder Agersnap, artist, ceramist, and art teacher (1946-2008).<br />
              Having worked for the United Nations in New York, USA, and Panama City, Panama, Louise Agersnap now leads the World Health Organization’s Innovation Hub, in Geneva, Switzerland, and resides with her family at her home and Gallery in France.<br />
              A selling artist Louise Agersnap’s production is featured amongst other places at the Van Gogh Art Gallery in Madrid, Spain, and at the International Contemporary Art Fair in Luxembourg, as well as on social media.

            </p>
          </div>
          <div className="right">
            <Swiper
              effect={"flip"}
              grabCursor={true}
              navigation={true}
              loop={true}
              modules={[EffectFlip, Navigation]}
              className="mySwiper"
            >
              <SwiperSlide>
                <img src="/assets/Joyousblues.jpg" />
                <h3>Joyous blues</h3>
              </SwiperSlide>
              <SwiperSlide>
                <img src="/assets/LifeIs.jpg" />
                <h3>Life Is</h3>
              </SwiperSlide>
              <SwiperSlide>
                <img src="/assets/LifeIsII.jpg" />
                <h3>Life Is II</h3>
              </SwiperSlide>
              <SwiperSlide>
                <img src="/assets/Photosynthesis.jpg" />
                <h3>Photosynthesis</h3>
              </SwiperSlide>
              <SwiperSlide>
                <img src="/assets/BlueIII.jpg" />
                <h3>Blue III</h3>
              </SwiperSlide>
              <SwiperSlide>
                <img src="/assets/GalaxyII.jpg" />
                <h3>Galaxy II</h3>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>

      <div id="contact">
        <div className="ellipse2">
          <img src="/assets/ellipse.svg" alt="" />
        </div>
        <div className="card2">
          <div className="left2">
            <h2>Contact</h2>
            <p>
              Contact Louise Agersnap for inquiries, purchase, or commission of artwork, or to schedule a visit in her Home Gallery.
            </p>
          </div>
          <div className="right2">
            <a href="mailto:louiseagersnap@mac.com" target="_blank" rel="noreferrer">louiseagersnap@mac.com</a>
          </div>
        </div>
      </div>
      <footer>
        <p>© 2023 Louise Agersnap</p>
      </footer>
    </div>
  );
}

export default App;
